import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Loading, MultiSelect, Select } from 'sw-ui';
import Autocomplete from 'react-autocomplete';
import DatePicker from 'react-datetime';

import RequestList from './components/requestsList';
import Button from '../../../../components/button/Button';
import TextArea from '../../../../components/textarea';
import UploadForm from '../../../../components/UploadForm';
import SelectDepartment from '../../../trip/components/selectDepartment';
import SelectProject from '../../../trip/components/selectProject';
import Input from '../../../../components/input';

import {
  isBeforeDate,
  isSameDate,
} from '../../../../bi/utils/formatDate';
import { findDepartments } from '../../../../bi/utils/trip';

import {
  BUTTON_LABELS,
  DATE_FIELDS,
  LABELS,
  PRICE_VALUES,
  VAT_VALUES,
  CONTACTS_FIELDS,
  NOTIFICATION_STATE,
  WARNINGS,
  THEME,
  WRAP_STYLE,
  INPUT_PROPS,
  REQUIRED_EXTENSIONS,
  UPLOAD_FORM,
  TEXT_AREA_HEIGHT,
  REQUEST_STATUS_LABELS,
  REQUEST_STATUSES,
  COMMISSION,
  URL_QUERY_PARAMS,
  TIMEOUT,
} from '../../../../bi/constants/hotelBookRequest';
import { FIELDS } from '../../../../bi/constants/hotels';
import {
  DEFAULTDATE,
  DEFAULTTIME,
} from '../../../../constants/time';

import styles from './index.module.css';

const HotelBookRequest = ({
    companyService,
    hotelsService,
    hotelsService: {
      changeField,
      saveHotelBookRequest,
      requestHotelInfo,
      getSavedRequests,
      setCustomersList,
      setSelectedCustomers,
      changeSelectedHotel,
      changeVatValues,
      changeHasEarlyCheckin,
      changeHasLateCheckout,
      changeHasFreeCancellation,
      changeHasBreakfast,
      changeCheckDates,
      changeCheckinTime,
      changeCheckoutTime,
      changePrices,
      changeIntercomLink,
      changeSelectedRooms,
      changeAdditionalEmail,
      changeHotelInput,
      setContacts,
      setAdditionalComment,
      setSelectedRooms,
      reset,
      deleteSavedRequest,
      changeIsEditing,
      getSavedRequest,
      saveEditedRequest,
      sendRequestToHotel,
      uploadCustomTemplate,
      resetCustomTemplate,
      changeIsSelfEmployed,
      changeRequestStatus,
      setSelectedDepartment,
      setProjectId,
      getMandatoryProject,
      getCustomerInfo,
      getSelectedCustomersByIds,
      getRequestUsers,
      createNewTripFromRequest,
      setCommission,
      setHotelId,
      setRequestUserId,
      setCustomAnalytics,
    },
    searchHotelsInput,
    notificationService,
    companyId,
    accountId,
  }) => {
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isRequestFromCart, setIsRequestFromCart] = useState(false);

  const {
    vatAmount,
    checkinDate,
    checkinTime,
    checkoutDate,
    checkoutTime,
    prices,
    customersList,
    selectedCustomers,
    hotelInfo,
    savedRequests,
    roomTypes,
    selectedRooms,
    isAdditionalEmail,
    hasEarlyCheckin,
    hasLateCheckout,
    hasBreakfast,
    hasFreeCancellation,
    freeCancellationDate,
    contacts,
    noContacts: {
      noEmail,
      noPhone,
    },
    additionalComment,
    isLoading,
    intercomLink,
    isEditing,
    customTemplate,
    editedRequest: {
      status,
      id,
      customerEmail,
      cartId,
    },
    isSelfEmployed,
    requestStatus,
    customer,
    MandatoryProject,
    ProjectId,
    customerProjects,
    btnRequest,
    SelectedDepartmentId,
    commission,
    selectedHotel,
    requestUsers,
    requestUserId,
    newTripFailure,
    newTripSuccess,
    hotelNotFound,
    accountAnalytics,
    selectedAnalytics,
  } = hotelsService.get();

  useEffect(() => {
    const requestIdFromUrl = new URL(window.location.href).searchParams.get(URL_QUERY_PARAMS.REQUEST_ID);

    if (requestIdFromUrl) {
      getSavedRequest(requestIdFromUrl, accountId);
      setIsRequestFromCart(!isRequestFromCart);

      const timer = setTimeout(() => {
        setIsRequestFromCart(false);
      }, TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (requestUsers && requestUsers.length > 0) {
      return;
    }

    getRequestUsers(companyId);
  }, [companyId, id]);

  useEffect(() => {
    if (customersList && customersList.length > 0) {
      return;
    }

    const updateEmployees = async () => {
      const { employees } = await companyService.get();

      try {
        if ((JSON.stringify(customersList) !== JSON.stringify(employees)) || !customersList) {
          setCustomersList(employees);
        }
      } catch (error) {
        setCustomersList([]);
      }
    };

    companyService.employees.loadEmployees();

    const unsubscribeEmployees = companyService.subscribe(updateEmployees);

    return () => {
      if (unsubscribeEmployees) unsubscribeEmployees();
    };
  }, [companyService, customersList]);

  useEffect(() => {
    const getEmployeeInfo = (employees) => {
      if (!employees || !employees[0]) return;

      getCustomerInfo(employees[0]);
    };

    if (selectedCustomers) {
      getEmployeeInfo(selectedCustomers);
      getMandatoryProject(accountId);
      getSelectedCustomersByIds();
    }
  }, [selectedCustomers]);

  useEffect(() => {
    const updateHotels = state => hotelsService.get({
      ...state,
    });

    const unsubscribeHotels = hotelsService.subscribe(updateHotels);

    return () => {
      if (unsubscribeHotels) unsubscribeHotels();
    };
  }, [hotelsService, hotelInfo, savedRequests]);

  useEffect(() => {
    if (!hasEarlyCheckin) {
      changePrices(PRICE_VALUES.earlyCheckinPrice, '');
    }

    if (!hasLateCheckout) {
      changePrices(PRICE_VALUES.lateCheckoutPrice, '');
    }

    if (!hasBreakfast) {
      changePrices(PRICE_VALUES.breakfastPrice, '');
    }
  }, [hasEarlyCheckin, hasLateCheckout, hasBreakfast]);

  const handleSendNotification = (label, state) => {
    notificationService.send({
      header: label,
      level: notificationService.levels.get(state),
    });
  };

  useEffect(() => {
    if (noEmail && noPhone) {
      handleSendNotification(WARNINGS.NO_CONTACTS, NOTIFICATION_STATE.FAILURE);
    }
  }, [noEmail, noPhone]);

  useEffect(() => {
    const validateSelections = () => {
      if (isSameDate(checkinDate, checkoutDate)) {
        return;
      }

      if (isBeforeDate(checkoutDate, checkinDate)) {
        return handleSendNotification(WARNINGS.DATE_WARNING, NOTIFICATION_STATE.FAILURE);
      }
    };

    validateSelections();
  }, [checkinDate, checkoutDate]);

  useEffect(() => {
    if (newTripFailure) {
      handleSendNotification(WARNINGS.NEW_TRIP_FAILURE, NOTIFICATION_STATE.FAILURE);
    }

    if (newTripSuccess) {
      handleSendNotification(WARNINGS.NEW_TRIP_SUCCESS, NOTIFICATION_STATE.SUCCESS);
    }
  }, [newTripFailure, newTripSuccess]);

  useEffect(() => {
    if (hotelNotFound) {
      handleSendNotification(WARNINGS.HOTEL_NOT_FOUND, NOTIFICATION_STATE.FAILURE);
    }
  }, [hotelNotFound]);

  const handleSendRequest = (event) => {
    event.preventDefault();

    if (!hotelInfo.email && !contacts.email) {
      return handleSendNotification(WARNINGS.NO_EMAIL, NOTIFICATION_STATE.FAILURE);
    }

    if (!intercomLink) {
      return handleSendNotification(WARNINGS.NO_INTERCOM_LINK, NOTIFICATION_STATE.FAILURE);
    }

    if (!selectedCustomers) {
      return handleSendNotification(WARNINGS.NO_CUSTOMERS, NOTIFICATION_STATE.FAILURE);
    }

    if (!hotelInfo.Id) {
      return handleSendNotification(WARNINGS.HOTEL_NOT_FOUND, NOTIFICATION_STATE.FAILURE);
    }

    if (isEditing) {
      saveEditedRequest(companyId, accountId);

      return handleSendNotification(WARNINGS.SUCCESS, NOTIFICATION_STATE.SUCCESS);
    }

    saveHotelBookRequest(companyId, accountId)
      .then(() => {
        getSavedRequests(companyId, accountId);
        handleSendNotification(WARNINGS.SUCCESS, NOTIFICATION_STATE.SUCCESS);
      })
      .catch(() => {
        handleSendNotification(WARNINGS.FAILURE, NOTIFICATION_STATE.FAILURE);
      });
  };

  const handleSendRequestToHotel = async () => {
    if (!hotelInfo.email && !contacts.email) {
      return handleSendNotification(WARNINGS.NO_EMAIL, NOTIFICATION_STATE.FAILURE);
    }

    if (!selectedCustomers || !selectedCustomers.length) {
      return handleSendNotification(WARNINGS.NO_CUSTOMERS, NOTIFICATION_STATE.FAILURE);
    }

    if (!selectedHotel) {
      return handleSendNotification(WARNINGS.NO_HOTEL_SELECTED, NOTIFICATION_STATE.FAILURE);
    }

    if (!intercomLink) {
      return handleSendNotification(WARNINGS.NO_INTERCOM_LINK, NOTIFICATION_STATE.FAILURE);
    }

    if (!prices.dailyPrice) {
      return handleSendNotification(WARNINGS.NO_DAILY_PRICE, NOTIFICATION_STATE.FAILURE);
    }

    if (!hotelInfo.Id) {
      return handleSendNotification(WARNINGS.HOTEL_NOT_FOUND, NOTIFICATION_STATE.FAILURE);
    }

    setIsSendingRequest(true);

    try {
      const emailSubject = await sendRequestToHotel(companyId, accountId);

      handleSendNotification(WARNINGS.SEND_SUCCESS, NOTIFICATION_STATE.SUCCESS);
      handleSendNotification(WARNINGS.SUCCESS, NOTIFICATION_STATE.SUCCESS);

      if (emailSubject) {
        const email = hotelInfo.email ? hotelInfo.email : contacts.email;

        setIsSendingRequest(false);
        handleSendNotification(WARNINGS.SUCCESS_EMAIL_SUBJ(email, emailSubject), NOTIFICATION_STATE.SUCCESS);
      }
    } catch (err) {
      setIsSendingRequest(false);
      handleSendNotification(WARNINGS.SEND_FAILURE, NOTIFICATION_STATE.FAILURE);
    }
  };

  const handleSelectHotel = (value, item) => {
    if (!value) return;

    if (value !== selectedHotel) {
      setHotelId(0);
    }

    changeField(value, FIELDS.SEARCH.VALUE);
    changeSelectedHotel(value);
    requestHotelInfo(item);
  };

  const handleMoveToCart = (userId) => {
    const path = `/account/${accountId}/company/${companyId}/user/${userId}`;

    window.open(path, '_blank');
  };

  const addNewTrip = () => {
    if (!requestUserId) {
      return handleSendNotification(WARNINGS.NO_INITIATOR, NOTIFICATION_STATE.FAILURE);
    }

    return createNewTripFromRequest(companyId, accountId);
  };

  const renderMenu = items => (
    <div className={ styles.autocomplete }>
      <div className={ styles.items_wrap }>
        {items}
      </div>
    </div>
  );

  const renderItem = item =>
    item.IsRegion
      ? <div key={ item.Id } />
      : (
        <div key={ item.Id } className={ styles.autocomplete_item }>
          <div className={ styles.autocomplete_item_text }>{item.Name}</div>
          <div className={ styles.autocomplete_item_label }>{item.FullName}</div>
        </div>
      );

  const renderAutoComplete = () => {
    const { value: currentValue, suggest } = searchHotelsInput;

    return (
      <Autocomplete
        value={ currentValue }
        inputProps={ INPUT_PROPS }
        items={ suggest }
        getItemValue={ item => item.Name }
        onSelect={ (value, item) => handleSelectHotel(value, item) }
        onChange={ ({ target: { value } }) => changeHotelInput(value) }
        renderItem={ item => renderItem(item) }
        renderMenu={ items => renderMenu(items) }
        wrapperStyle={ WRAP_STYLE }
      />
    );
  };

  const renderHotelConditionsInput = (condition, priceName) => condition ? (
    <div>
      <Input
        type={ 'number' }
        field={ LABELS.PRICE }
        placeholder={ LABELS.PRICE }
        value={ prices[priceName] }
        onChange={ ({ target: { value } }) => changePrices(priceName, value) }
        className={ styles.conditions_input }
        min='0'
      />
    </div>
  ) : null;

  const renderCheckinTime = () => (
    <div>
      <Input
        type='text'
        field={ LABELS.CHECKIN_TIME }
        placeholder={ LABELS.CHECKIN_TIME }
        value={ checkinTime }
        onChange={ ({ target: { value } }) => changeCheckinTime(value) }
        className={ styles.conditions_input }
      />
    </div>
  );

  const renderCheckoutTime = () => (
    <div>
      <Input
        type='text'
        field={ LABELS.CHECKOUT_TIME }
        placeholder={ LABELS.CHECKOUT_TIME }
        value={ checkoutTime }
        onChange={ ({ target: { value } }) => changeCheckoutTime(value) }
        className={ styles.conditions_input }
      />
    </div>
  );

  const renderEmployeesSelector = () => (
    <MultiSelect
      label={ LABELS.GUEST_FULL_NAME }
      items={ customersList || [] }
      values={ selectedCustomers }
      search
      searchPlaceholder={ LABELS.GUEST_FULL_NAME }
      onChange={ setSelectedCustomers }
      overflow
    />
  );

  const renderTypeOfRoomSelector = () => {
    if (!roomTypes || roomTypes.length === 0) {
      return (
        <Input
          placeholder={ LABELS.ROOM_CATEGORY }
          field={ LABELS.ROOM_CATEGORY }
          value={ selectedRooms || '' }
          onChange={ ({ target: { value } }) => changeSelectedRooms(value) }
          className={ styles.conditions_input }
          min='0'
        />
      );
    }

    return (
      <Select
        label={ LABELS.ROOM_CATEGORY }
        items={ roomTypes }
        value={ selectedRooms.value || '' }
        onChange={ item => setSelectedRooms(item) }
        className={ styles.type_of_room }
        theme={ THEME.BORDER }
        withLabel
        smooth
        overflow
      />
    );
  };


  const renderRoomInfo = () => (
    <div className={ `${styles.date_pickers} ${styles.divider}` }>
      <div className={ styles.date_picker_wrap }>
        <div>{LABELS.CHECKIN_DATE}</div>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat=''
          value={ checkinDate }
          onChange={ value => changeCheckDates(DATE_FIELDS.CHECKIN_DATE, value) }
          type={ 'date' }
          className={ styles.date_picker }
        />
      </div>
      <div className={ styles.date_picker_wrap }>
        <div>{LABELS.CHECKOUT_DATE}</div>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat=''
          value={ checkoutDate }
          onChange={ value => changeCheckDates(DATE_FIELDS.CHECKOUT_DATE, value) }
          type={ 'date' }
          className={ styles.date_picker }
        />
      </div>
      <div className={ styles.width }>
        {renderEmployeesSelector()}
      </div>
      <div className={ styles.width }>
        {renderTypeOfRoomSelector()}
      </div>
    </div>
  );

  const renderConditionCheckbox = (condition, fn, label) => (
    <Checkbox
      value={ condition }
      onChange={ () => fn(!condition) }
      className={ styles.checkbox_width }
    >
      {label}
    </Checkbox>
  );

  const renderFreeCancellationDatePick = condition => condition ? (
    <div
      className={ `${styles.date_picker_wrap}${styles.date_picker_wrap_free_cancellation}` }
    >
      <div>{LABELS.FREE_CANCELLATION_DATE}</div>
      <DatePicker
        dateFormat={ DEFAULTDATE }
        timeFormat={ DEFAULTTIME }
        value={ freeCancellationDate }
        onChange={ value => changeCheckDates(DATE_FIELDS.FREE_CANCELLATION_DATE, value) }
        type={ 'datetime' }
        className={ styles.date_picker }
      />
    </div>
  ) : null;

  const renderHotelConditions = () => (
    <div className={ `${styles.hotel_conditions} ${styles.divider}` }>
      <div className={ styles.condition }>
        {renderConditionCheckbox(hasEarlyCheckin, changeHasEarlyCheckin, LABELS.EARLY_CHECKIN)}
        {renderHotelConditionsInput(hasEarlyCheckin, PRICE_VALUES.earlyCheckinPrice)}
        {renderCheckinTime()}
      </div>
      <div className={ styles.condition }>
        {renderConditionCheckbox(hasLateCheckout, changeHasLateCheckout, LABELS.LATE_CHECKOUT)}
        {renderHotelConditionsInput(hasLateCheckout, PRICE_VALUES.lateCheckoutPrice)}
        {renderCheckoutTime()}
      </div>
      <div className={ styles.condition }>
        {renderConditionCheckbox(hasBreakfast, changeHasBreakfast, LABELS.BREAKFAST)}
      </div>
    </div>
    );

  const renderFreeCancellationConditions = () => {
    const clientLabel = hasFreeCancellation ? <label>{LABELS.TOTAL_FOR_CLIENT}</label> : null;
    const smartwayLabel = hasFreeCancellation ? <label>{LABELS.TOTAL_FOR_SMARTWAY}</label> : null;

    return (
      <div className={ `${styles.cancellation_fees} ${styles.divider}` }>
        <div className={ `${styles.free_cancellation} ${styles.cancellation_for_client}` }>
          {renderConditionCheckbox(hasFreeCancellation, changeHasFreeCancellation, LABELS.FREE_CANCELLATION)}
          {renderFreeCancellationDatePick(hasFreeCancellation)}
        </div>
        <div className={ styles.free_cancellation }>
          {clientLabel}
          {renderHotelConditionsInput(hasFreeCancellation, PRICE_VALUES.cancellationFeeTotal)}
        </div>
        <div className={ styles.free_cancellation }>
          {smartwayLabel}
          {renderHotelConditionsInput(hasFreeCancellation, PRICE_VALUES.cancellationFeeBase)}
        </div>

      </div>
    );
  };

  const renderContactsInfo = () => {
    const email = hotelInfo.email || contacts.email;
    const phone = hotelInfo.phone || contacts.phone;

    return (
      <div className={ `${styles.contacts} ${styles.divider}` }>
        <Input
          type={ CONTACTS_FIELDS.EMAIL }
          field={ CONTACTS_FIELDS.EMAIL }
          placeholder={ LABELS.EMAIL }
          value={ email }
          onChange={ ({ target: { value } }) => setContacts(CONTACTS_FIELDS.EMAIL, value) }
          className={ `${styles.width} ${styles.input}` }
        />
        <Input
          type={ CONTACTS_FIELDS.PHONE }
          field={ CONTACTS_FIELDS.PHONE }
          placeholder={ LABELS.PHONE }
          value={ phone }
          onChange={ ({ target: { value } }) => setContacts(CONTACTS_FIELDS.PHONE, value) }
          className={ `${styles.width} ${styles.input}` }
        />
      </div>
    );
  };

  const renderAdditionalEmail = () => {
    const additionalInput = isAdditionalEmail ? (
      <Input
        type={ CONTACTS_FIELDS.ADDITIONAL_EMAIL }
        field={ CONTACTS_FIELDS.ADDITIONAL_EMAIL }
        placeholder={ LABELS.EMAIL }
        value={ contacts.additionalEmail }
        onChange={ ({ target: { value } }) => setContacts(CONTACTS_FIELDS.ADDITIONAL_EMAIL, value) }
        className={ styles.input }
      />
    ) : null;

    return (
      <div className={ styles.condition }>
        <Checkbox
          value={ isAdditionalEmail }
          onChange={ () => changeAdditionalEmail(!isAdditionalEmail) }
          className={ styles.checkbox_width }
        >
          {LABELS.ADDITIONAL_EMAIL}
        </Checkbox>
        {additionalInput}
      </div>
    );
  };

  const renderSubmitButton = () => {
    const label = isEditing ? BUTTON_LABELS.SAVE_EDITED : BUTTON_LABELS.SAVE_DRAFT;

    if (status === REQUEST_STATUS_LABELS.TripAdded) return null;

    return (
      <Button
        label={ label }
        className={ styles.button_wrap }
        type='submit'
      />
    );
  };

  const renderCustomTemplateUploader = () => {
    const { uploaded, file } = customTemplate;

    return (
      <UploadForm
        extensions={ REQUIRED_EXTENSIONS }
        invalidExtension={ UPLOAD_FORM.INVALID_EXTENSION }
        chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
        description={ UPLOAD_FORM.DESCRIPTION }
        className={ styles.upload_form }
        onFileAdded={ uploadCustomTemplate }
        onFileRemoved={ resetCustomTemplate }
        uploaded={ uploaded }
        file={ file }
      />
    );
  };

  const renderNewTripButton = () => {
    if (!(status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === 1)) return null;

    if (btnRequest) {
      return (
        <div className={ styles.new_trip_waiting }>
          <Loading />
          <label>{LABELS.NEW_TRIP_WAITING}</label>
        </div>
      );
    }

    return (
      <Button
        label={ LABELS.NEW_TRIP }
        onClick={ addNewTrip }
        className={ styles.button_wrap }
      />
    );
  };

  const renderStatusSelector = () => {
    const isValidStatus = status && isEditing && (status === REQUEST_STATUS_LABELS.Pending);

    if (!isValidStatus) return null;

    return (
      <Select
        label={ LABELS.REQUEST_STATUS }
        items={ REQUEST_STATUSES }
        value={ requestStatus }
        onChange={ changeRequestStatus }
        className={ styles.hotel_item_selector }
        theme={ THEME.BORDER }
        withLabel
        smooth
        overflow
      />
    );
  };

  const renderUserSelect = () => {
    if (!requestUsers) return null;

    return (
      <div>
        <label>{LABELS.INITIATOR}</label>
        <Select
          className={ styles.user_selector }
          theme={ THEME.LIGHT }
          hideLabel
          search
          nullable
          label={ LABELS.SELECT_USER }
          items={ requestUsers }
          value={ requestUserId }
          onChange={ value => setRequestUserId(value) }
        />
      </div>
    );
  };

  const renderCustomAnalytics = () => accountAnalytics.reduce((acc, { Id, Name, Values }) => {
    if (status !== REQUEST_STATUS_LABELS.Confirmed && requestStatus !== 1) return null;

    if (!Values.length) return acc;

    const currentValue = selectedAnalytics[Id] || null;

    return [
      ...acc,
      <div key={ Id } className={ styles.selector }>
        <label>{LABELS.CUSTOM_ANALYTICS}</label>
        <Select
          className={ styles.user_selector }
          theme={ THEME.LIGHT }
          hideLabel
          search
          nullable
          label={ Name }
          items={ Values }
          value={ currentValue }
          onChange={ value => setCustomAnalytics(Id, value) }
        />
      </div>,
    ];
  }, []);

  const renderConfirmedRequestData = () => {
    const isConfirmed = status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === 1;

    const { Id = 0 } = customer;
    const userDepartments = findDepartments(customer, companyId);
    const departmentId = userDepartments.length > 0 ? userDepartments[0].Id : 0;
    const depId = departmentId || SelectedDepartmentId;

    return isConfirmed && isEditing ? (
      <div className={ styles.selectors }>
        <SelectDepartment
          isHotel
          departments={ userDepartments }
          employeesList={ customersList }
          currentDepartmentId={ depId }
          currentEmployeeId={ Id }
          onSelect={ setSelectedDepartment }
        />
        <SelectProject
          disabled={ !customerProjects }
          currentProjectId={ ProjectId }
          mandatoryProject={ MandatoryProject }
          projects={ customerProjects }
          onSelect={ setProjectId }
        />
        {renderUserSelect()}
      </div>
    ) : null;
  };

  const renderSendRequestButton = () => {
    if (status === REQUEST_STATUS_LABELS.TripAdded) return null;

    if (isSendingRequest) {
      return (
        <div className={ styles.request_sending }>
          <Loading />
          <label>{LABELS.SENDING_REQUEST}</label>
        </div>
      );
    }

    return (
      <Button
        label={ BUTTON_LABELS.SEND }
        className={ styles.button_wrap }
        onClick={ handleSendRequestToHotel }
      />
    );
  };

  const renderMoveToCartButton = () => {
    const { value } = requestUsers.find(({ label }) => label === customerEmail) || {};

    if (!value || !cartId || status === REQUEST_STATUS_LABELS.TripAdded) return null;

    return (
      <Button
        label={ BUTTON_LABELS.MOVE_TO_CART }
        className={ styles.button_wrap }
        onClick={ () => handleMoveToCart(value) }
      />
    );
  };

  const renderCommission = () => {
    const isConfirmed = status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === 1;

    if (!isConfirmed) return null;

    return (
      <div>
        <label>{COMMISSION.LABEL}</label>
        <Input
          type={ 'number' }
          field={ COMMISSION.FIELD }
          placeholder={ LABELS.PRICE }
          value={ commission }
          onChange={ ({ target: { value } }) => setCommission(value) }
          className={ styles.commission_input }
        />
      </div>
    );
  };

  const renderNewReqWarning = () => {
    if (!isRequestFromCart) return null;

    return (
      <div className={ styles.pre_send_warning }>
        { WARNINGS.CHECK_BEFORE_SEND }
      </div>
    );
  };

  const renderCommissionAndAnalytics = () => (
    <div className={ styles.selectors }>
      {renderCommission()}
      {renderCustomAnalytics()}
    </div>
  );

  return (
    <div>
      <div className={ styles.wrap }>
        <form className={ styles.form_wrap } onSubmit={ handleSendRequest }>
          <div className={ styles.form_inner }>
            { renderNewReqWarning() }
            <div className={ `${styles.hotel_wrap} ${styles.divider}` }>
              {renderAutoComplete()}
              <div className={ styles.hotel_item }>
                <Select
                  label={ LABELS.TYPE_OF_VAT }
                  items={ VAT_VALUES }
                  value={ vatAmount }
                  onChange={ changeVatValues }
                  className={ styles.hotel_item_selector }
                  theme={ THEME.BORDER }
                  withLabel
                  smooth
                  overflow
                />
              </div>
              <div className={ styles.hotel_item }>
                <Input
                  type='number'
                  field={ PRICE_VALUES.dailyPrice }
                  placeholder={ LABELS.DAILY_PRICE }
                  value={ prices.dailyPrice }
                  onChange={ ({ target: { value } }) => changePrices(PRICE_VALUES.dailyPrice, value) }
                  className={ styles.input }
                />
              </div>
              <div className={ styles.hotel_item }>
                <Input
                  type='text'
                  field={ LABELS.INTERCOM_LINK }
                  placeholder={ LABELS.INTERCOM_LINK }
                  value={ intercomLink }
                  onChange={ ({ target: { value } }) => changeIntercomLink(value) }
                  className={ styles.input }
                />
              </div>
              {renderStatusSelector()}
            </div>
            {renderRoomInfo()}
            {renderHotelConditions()}
            {renderFreeCancellationConditions()}
            {renderContactsInfo()}
            <div className={ `${styles.hotel_conditions} ${styles.divider}` }>
              {renderAdditionalEmail()}
              {renderConditionCheckbox(isSelfEmployed, changeIsSelfEmployed, LABELS.SELF_EMPLOYED)}
            </div>
            <div className={ styles.additional_info }>
              {renderCustomTemplateUploader()}
              <TextArea
                className={ styles.textarea }
                value={ additionalComment }
                placeholder={ LABELS.COMMENT }
                height={ TEXT_AREA_HEIGHT }
                onChange={ event => setAdditionalComment(event) }
              />
            </div>
            {renderConfirmedRequestData()}
            {renderCommissionAndAnalytics()}
          </div>
          <div className={ styles.form_buttons }>
            <Button
              label={ BUTTON_LABELS.RESET }
              className={ styles.button_wrap }
              type='reset'
              onClick={ reset }
            />
            {renderSubmitButton()}
            {renderSendRequestButton()}
            {renderNewTripButton()}
            {renderMoveToCartButton()}
          </div>
        </form>
      </div>
      <div className={ styles.wrap }>
        <RequestList
          companyId={ companyId }
          accountId={ accountId }
          savedRequests={ savedRequests }
          customers={ customersList }
          isLoading={ isLoading }
          onDelete={ deleteSavedRequest }
          loadRequests={ getSavedRequests }
          changeIsEditing={ changeIsEditing }
          onEdit={ getSavedRequest }
        />
      </div>
    </div>
  );
};

HotelBookRequest.propTypes = {
  companyId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  companyService: PropTypes.object.isRequired,
  hotelsService: PropTypes.object.isRequired,
  searchHotelsInput: PropTypes.object.isRequired,
  notificationService: PropTypes.object.isRequired,
};

export default HotelBookRequest;
